import { useStable } from '@/hooks/useStable';
import { mergeProps } from '@/utils/merge';
import { StandaloneContentLock } from 'base/components/ContentLock';
import { useMemo } from 'react';

export const AllasStandaloneContentLock: typeof StandaloneContentLock = (props) => {
  const headline = useMemo(
    () => (
      <>
        Vill du läsa hela artikeln?
        <br />
        Skapa ett konto på Allas – helt gratis!
      </>
    ),
    [],
  );

  const content = useStable({
    login: {
      caption: 'Medlemmar får mer – spara artiklar, tävla med korsord och obegränsad läsning.',
      headline,
    },
  });

  return <StandaloneContentLock {...mergeProps({ content }, props)} />;
};
