import { FactBox } from '@/components/FactBox';
import { LabradorComponent } from '@/types/component';
import { withBackgroundColor } from '@/utils/withBackgroundColor';

import { renderContentComponent } from 'modules/dynamic/components';

export const AllasLabradorFactBox: LabradorComponent = ({ type, meta, data, descendants }) => {
  const images = descendants.map(renderContentComponent);

  const backgroundColor = withBackgroundColor(meta.backgroundColor) ?? 'bg-radical-red-300';

  return (
    <FactBox
      headline={data.title}
      markup={data.bodytext}
      elements={images}
      data-content-index={meta.contentIndex}
      options={{ className: backgroundColor }}
    />
  );
};
