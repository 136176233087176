import { tw } from '@/utils/tw';
import TextAreaTheme from 'base/components/TextArea/theme';

const { Caption } = TextAreaTheme;

const TextArea = tw.theme({
  extend: TextAreaTheme,
  slots: {
    label: [
      'group-data-[status=error]:!text-error-600',
      'text-gray-900',
      'group-data-[status=error]:text-error-500',
      'group-focus-within:text-body-2xs',
      'group-data-[filled]:text-body-2xs',
      'absolute',
      'left-2',
      'top-1/2',
      'z-1',
      '-translate-y-1/2',
      'transition-all',
      'group-focus-within:top-1',
      'group-focus-within:translate-y-1.5',
      'group-data-[filled]:top-1',
      'group-data-[filled]:translate-y-1.5',
      'text-body-md',
      'group-focus-within:text-body-sm',
      'group-data-[filled]:text-body-sm',
    ],
    input: [
      'focus:bg-white',
      'focus:outline-0',
      'focus:placeholder:text-black',
      'focus:ring-1',
      'focus:ring-logga-600',
      'group-data-[status=error]:bg-white',
      'group-data-[status=error]:focus:outline-2',
      'group-data-[status=error]:hover:outline-2',
      'group-data-[status=error]:hover:ring-error-500',
      'group-data-[status=error]:outline-0',
      'group-data-[status=error]:outline-error-100',
      'group-data-[status=error]:placeholder:text-black',
      'group-data-[status=error]:ring-1',
      'group-data-[status=error]:ring-error-500',
      'hover:placeholder:text-black',
      'outline-offset-0',
      'placeholder:text-gray-600',
      'ring-gray-600',
    ],
  },
});

export default Object.assign(TextArea, { Caption });
