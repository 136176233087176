import { tw } from '@/utils/tw';
import BaseFactBox from 'base/components/FactBox/theme';

const { Prose } = BaseFactBox;

const FactBox = tw.theme({
  extend: BaseFactBox,
  slots: {
    base: 'my-6 rounded bg-logga-300',
    headline: 'text-headline-sm text-black normal-case',
  },
});

export default Object.assign(FactBox, { Prose });
