import { useStableProps } from '@/hooks/useStable';
import { StandaloneAuthorsList } from 'base/components/AuthorsList';

export const AllasStandaloneAuthorsList: typeof StandaloneAuthorsList = (props) => {
  const stableProps = useStableProps(
    {
      options: {
        $item: {
          $icon: {
            size: 'extraLarge',
          },
        },
      },
    },
    props,
  );

  return <StandaloneAuthorsList {...stableProps} />;
};
