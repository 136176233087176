import { mergeProps } from '@/utils/merge';
import { StandaloneBlogSlider } from 'base/components/BlogSlider';

export const AllasStandaloneBlogSlider: typeof StandaloneBlogSlider = (props) => {
  return (
    <StandaloneBlogSlider
      {...mergeProps(
        {
          options: {
            $slide: {
              $image: {
                className: `aspect-square rounded-full`,
              },
            },
          },
        },
        props,
      )}
    />
  );
};
