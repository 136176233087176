import { StandaloneInstagramArticlesList } from 'base/components/InstagramArticlesList';

export const AllasStandaloneInstagramArticlesList: typeof StandaloneInstagramArticlesList = ({
  articles,
  ...props
}) => {
  articles = articles?.slice(0, articles?.length - ((articles?.length ?? 0) % 3));

  return (
    <StandaloneInstagramArticlesList
      headline="Instagram – allas.se"
      description="Här hittar du alla artiklar från allas.se som du kan ha sett på Instagram."
      articles={articles}
      {...props}
    />
  );
};
