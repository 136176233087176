import { tw } from '@/utils/tw';
import ContactEditorialTheme from 'base/components/ContactEditorial/theme';

const TextArea = tw.theme({
  extend: ContactEditorialTheme.TextArea,
  defaultVariants: {
    variant: 'secondary',
  },
});

const TextField = tw.theme({
  extend: ContactEditorialTheme.TextField,
  defaultVariants: {
    variant: 'secondary',
  },
});
const { Checkbox, Dialog } = ContactEditorialTheme;

const ContactEditorial = tw.theme({
  extend: ContactEditorialTheme,
  slots: {
    base: ['bg-dodger-blue-300'],
    headline: ['text-special-headline-sm', 'md:text-special-headline-lg', 'text-logga-600'],
  },
});

export default Object.assign(ContactEditorial, { TextField, TextArea, Checkbox, Dialog });
